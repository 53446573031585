<!-- 分期购车 -->
<template>
  <div class="CarList">
    <LineTab
      :style="`top:${isShowDownLoad ? '0.92' : '0.44'}rem`"
      :list="tabList"
      :active="activeKey"
      @tabChange="tabChange"
    >
      <img
        class="filter"
        src="@/assets/images/list_filter.png"
        @click="nextPage"
      />
    </LineTab>
    <div id="mainContent">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="isNoData ? '' : '到底了'"
        offset="100"
        @load="getList"
      >
        <div class="ads" v-show="isShowAd">
          <img :src="adInfo.photoUrl" alt="" />
        </div>
        <div
          class="car-wrap"
          v-for="(item, index) in carList"
          :key="item.styleId"
          :style="
            (index % 2 === 1 && !isShowAd) || (index % 2 === 0 && isShowAd)
              ? 'margin-left:5px'
              : 'margin-right:5px'
          "
        >
          <Card :source="item" />
        </div>
        <div class="noData" v-show="isNoData">
          <img src="@/assets/images/nodata.png" alt="" />
          <p>暂无内容</p>
        </div>
      </van-list>
      <!-- 回到顶部 -->
      <GoTop />
    </div>
  </div>
</template>

<script>
import LineTab from "@/components/LineTab";
import Card from "@/components/Card.vue";
import GoTop from "@/components/GoTop.vue";
import { getCarSeriesList } from "@/api/api_carInstallment";
import { getBannerList } from "@/api/api_home";
import { mapState } from "vuex";
export default {
  name: "CarList",
  data() {
    return {
      isRequest: false,
      list: [],
      loading: false,
      finished: false,
      carList: [],
      activeKey: "1",
      pageIndex: 1,
      pageSize: 6,
      brandId: this.$route.query.brandId,
      currentCity: this.$store.state.position,
      tabList: [
        { name: "热门", code: "1", isShow: true },
        { name: "0息购车", code: "2", isShow: true }
      ],
      adInfo: {}
    };
  },
  computed: {
    ...mapState(["isShowDownLoad", "position"]),
    // 是否暂无数据
    isNoData() {
      return this.carList.length === 0 && this.isRequest;
    },
    isShowAd() {
      return (
        this.adInfo.photoUrl &&
        (this.carList.length || this.isNoData) &&
        this.activeKey === "1"
      );
    }
  },
  created() {
    this.getImgList();
  },
  methods: {
    // 获取广告
    getImgList() {
      getBannerList({
        cityCode: this.position.cityId,
        adPage: 4
      }).then(res => {
        if (res.code === 0) {
          const info = res.data.info;
          this.adInfo =
            (info.installmentBanner && info.installmentBanner[0]) || {};
        }
      });
    },
    nextPage() {
      this.$router.push({ name: "BrandSelect" });
    },
    tabChange(code) {
      this.pageIndex = 1;
      this.activeKey = code;
      this.loading = true;
      this.finished = false;
      this.getList();
      window.scrollTo(0, 0);
    },
    // 获取车系列表
    getList() {
      this.isRequest = false;
      getCarSeriesList({
        searchType: this.activeKey, // 1热门，2零息
        masterBrandId: this.brandId, // 选择品牌返回必传
        cityId: this.currentCity.cityId,
        pageCount: this.pageIndex,
        rowCount: this.pageSize
      }).then(res => {
        if (res.code === 0) {
          if (this.pageIndex === 1) {
            this.carList = res.data.info;
          } else {
            this.carList = this.carList.concat(res.data.info);
          }
          this.pageIndex++;
          this.loading = false;
          // 数据全部加载完成
          const total = res.data.pageInfo.resultCount;
          if (this.carList.length >= total) {
            this.finished = true;
          }
        }
        this.isRequest = true;
      });
    }
  },
  components: { LineTab, Card, GoTop }
};
</script>
<style lang="scss" scoped>
.CarList {
  .LineTab {
    background: #fff;
    position: fixed;
    top: 0.44rem;
    left: 0;
    right: 0;
    z-index: 10;
    .filter {
      width: 0.16rem;
      height: 0.16rem;
    }
  }
  #mainContent {
    padding: 0.63rem 0.18rem;
    .ads {
      width: calc(50% - 5px);
      height: 2.22rem;
      margin-right: 5px;
      float: left;
      img {
        border-radius: 0.08rem;
        width: 100%;
        height: 2.22rem;
      }
    }
    .car-wrap {
      width: calc(50% - 5px);
      float: left;
    }
    .cardItem {
      display: inline-block;
      width: 100%;
    }
    .noData {
      position: fixed;
      z-index: 8888;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 1.3rem;
        height: 1.13rem;
      }
      p {
        color: #3a3d46;
        line-height: 0.22rem;
        margin-top: 0.16rem;
        text-align: center;
      }
    }
  }
}
</style>
