/**
 * 分期购车
 */

import axios from "./axios";

// 分期购车列表
export function getCarSeriesList(params) {
  return axios.post("/lyra-middleground/api/home/getCarSeriesList", params);
}

// 车型详情
export function getCarModelDetail(params) {
  return axios.post("/lyra-middleground/api/search/getCarModelDetail", params);
}

// 获取车系热度
export function getSerialHot(params) {
  return axios.post("/lyra-middleground/api/home/getSerialHot", params, {
    hideLoading: true
  });
}

// 查看车型列表
export function getStyleList(params) {
  return axios.post("/lyra-middleground/api/search/getStyleList", params);
}

// 购车咨询
export function loadClue(params) {
  return axios.post("/lyra-middleground/api/externalClue/loadClue", params);
}
