<!-- 回到顶部 -->
<template>
  <transition name="van-fade">
    <img
      v-show="visible"
      class="yx_go_top"
      src="@/assets/images/go_top.png"
      @click="goBack"
    />
  </transition>
</template>

<script>
export default {
  name: "GoTop",
  data() {
    return {
      visible: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 100) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    },
    goBack() {
      window.scrollTo(0, 0);
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
<style lang="scss" scoped>
.yx_go_top {
  position: fixed;
  left: 50%;
  bottom: 0.26rem;
  transform: translate(-50%);
  z-index: 10;
  width: 0.4rem;
  height: 0.4rem;
}
</style>
